// NEW CONSTANTS FOR SSR 


// Constants for getStaticPaths and getStaticProps 


// REVALIDATION TIMES - seconds 
export const INDEX_PROPS_REVALIDATE = 1*60*60  // INDEX PAGE 
export const INTEGRATIONS_PROPS_REVALIDATE = 1*60 // INTEGRATIONS PAGE - /integrations
export const CATEGORIES_INDEX_PROPS_REVALIDATE = 24*60*60  // CATEGORIES INDEX PAGE - /categories

export const APP_PROPS_REVALIDATE = 10*60  // APP PAGE - /trello
export const APP_INTEGRATIONS_PROPS_REVALIDATE = 1*60*60 // APP INTEGRATIONS PAGE - /trello/integrations
export const APP_INTEGRATION_PROPS_REVALIDATE = 24*60*60 // APP INTEGRATION PAGE - /trello/integrations/airtable
export const APP_ALTERNATIVES_PROPS_REVALIDATE = 1*60*60  // APP ALTERNATIVES PAGE - /trello/alternatives
export const APP_AUTOMATION_PROPS_REVALIDATE = 1*60*60  // APP AUTOMATION PAGE - /trello/automations/234234-blabla-bla-by-zapier
export const APPSCATEGORIES_PROPS_REVALIDATE = 60  // APPS CATEGORIES and APPS CATEGORY - /apps and /apps/categories/project-management
export const CATEGORY_PROPS_REVALIDATE = 60  // CATEGORY PAGE - /project-management - TODO - Category pages not build yet
export const COMPARISON_PROPS_REVALIDATE = 1*60   // COMPARISON /compare/trello-vs-asana
export const COMPANY_PROPS_REVALIDATE = 1*60 // COMPANY PAGE - /companies/microsoft
export const COMPANIES_PROPS_REVALIDATE = 1*60 // COMPANIES INDEX PAGE - /companies
export const FIND_APPS_PROPS_REVALIDATE = 1*60 // COMPANIES INDEX PAGE - /find-apps and /find-apps/[letter]

export const BLOGPOST_PROPS_REVALIDATE = 1*60 // BLOGPOST PAGE - /blog/hello-world
export const BLOG_INDEX_PROPS_REVALIDATE = 1*60 // BLOG_INDEX PAGE - /blog


// HOME PAGE 
export const SEARCH_MAX_ITEMS = 5  // number of items in search dropdown per entity type (ie 5 for apps, 5 for categories)
export const FEATURED_CATEGORIES_BUILD_MAX_APPS_PER_CAT = 5  // Max apps per featured category


// CATEGORY 
export const CATEGORY_APPS_BUILD_MAX = 28+14*2  // Max apps to load on Build 
export const CATEGORY_APPS_SHOW_MAX_INITIAL = 28  // Max apps to load on Build 
export const CATEGORY_APPS_LOAD_MORE_CHUNK = 14  // Apps to show on page 

export const CATEGORY_RELATED_AUTOMATIONS_BUILD_MAX = 9  // Max Related categories to show in the sidebar 

// APP
// export const APP_EXT_ACTIONS_BUILD_MAX = 500 // Max extAppActions to load on Build 
// export const APP_EXT_AUTOMATIONS_BUILD_MAX = 6 // Max extAutomations to load on Build 
export const APP_EXT_ACTIONS_SHOW_MAX_INITIAL = 6  // 
export const APP_EXT_ACTIONS_LOAD_MORE_CHUNK = 6  // 
// export const APP_APP_CONNECTIONS_BUILD_MAX = 4*5  // Max app_connections to load on Build
// export const APP_STACK_APPS_BUILD_MAX = 20  // Max app_connections to load on Build

// // APP (WHEN APP IS SOURCE)
// export const APP_SOURCE_INTEGRATIONS_BUILD_MAX = 4*5  // Max extApps (with Apps) to load on Build 


// APP CONNECTIONS
export const APPCONNECTIONS_CONNECTIONS_BUILD_MAX = 250*6 // Max appConnections to load on Build 
export const APPCONNECTIONS_SOURCE_INTEGRATIONS_BUILD_MAX = 10000 // Max extApps of AppSource to load on Build // When App is Source
export const APPCONNECTIONS_EXT_ACTIONS_BUILD_MAX = 500 // Max extAppActions to load on Build 
export const APPCONNECTIONS_SIDE_TOP_SOURCES_MAX = 5 // Max sources to list in the side panel 
export const APPCONNECTIONS_RELATED_CATEGORIES_MAX = 10 // Max related categories to show in RC Section
export const APPCONNECTIONS_ALTERNATIVES_BUILD_MAX = 6 // Max appConnections to load on Build 
export const APPCONNECTIONS_DEFAULT_SORTING = [ 
  { count_clicks: 'desc' }, { count_impressions: 'desc' }, 
  { count_sources_automation: 'desc' }, { count_external_automations: 'desc' }, 
  { id: 'asc' } 
] // Used on Many Pages

// APP CONNECTION PAGE 
export const APPCONNECTION_PAGE_EXT_ACTIONS_BUILD_MAX = 500 // Max extAppActions to load on Build 

// COMPANIES
export const COMPANIES_BUILD_MAX = 60  // Max companies to load on Build 
export const COMPANIES_SHOW_MAX_INITIAL = 3  // Max companies to show initially on Page
export const COMPANIES_LOAD_MORE_CHUNK = 3  // Companies chubk when pressing Load More button

// AUTOMATION PAGE (EXT AUTOMATION)
export const AUTOMATION_TRIGGER_APP_ALTERNATIVES_LIMIT = 10  // used for related automation generation 
export const AUTOMATION_ACTION_APPS_ALTERNATIVES_LIMIT = 10
export const AUTOMATION_RELATED_EXT_AUTOMATIONS_LIMIT = 18
export const AUTOMATION_EXT_ACTIONS_BUILD_MAX = 500 // Max extAppActions to load on Build 

// COMPARISON PAGE (COMPARE APPS)
export const COMPARISON_ALTERNATIVES_LIST_MAX = 5  // Max alternatives to show per app in the comparison alternatives block
export const COMPARISON_COMMON_ALTERNATIVES_MAX = 6  // Max Common (both from appA and appB) alternatives to show in comparison alternatives block
export const COMPARISON_CONNECTIONS_LIST_MAX = 50  // Max connections to load from DB per app in the comparison integrations block

export const COMPARISON_SOURCE_APPS_MAX = 50  // Max apps to fetch for compariosnSourceIntegrations
export const COMPARISON_SOURCE_APPS_UNIQ_MAX = 5  // Max apps which are unique to one of the sources

// FIND-APPS. LETTER PAGE. (COMPARE APPS)
export const FIND_APPS_LETTERS = [
  '0-9', 
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 
  'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 
  'u', 'v', 'w', 'x', 'y', 'z'
]
export const FIND_APPS_APP_PAGE_CONNECTIONS_MAX = 3*40// 129  // 

// BLOG 
export const BLOG_IMAGES_MAX_FILES = 2  // Max Files allowed to Upload
export const BLOG_IMAGES_MAX_FILE_SIZE = 20 * 1024 * 1024 // 200 * 1024 * 1024 (200mb)
// export const BLOG_IMAGES_UPLOAD_DIR = `${process.env.UPLOAD_IMAGES_PATH}/blogposts`  // Directory To Upload Files

export const IMAGES_UPLOAD_DIR = (process.env.NEXT_PUBLIC_ENV === 'development') ? 
  './public/uploads/images' : '/home/nocode/nocode_production/images'  // process.env.UPLOAD_IMAGES_PATH `${process.env.UPLOAD_IMAGES_PATH}/blogposts`
export const BLOG_IMAGES_UPLOAD_DIR = `${IMAGES_UPLOAD_DIR}/blogposts`
export const BLOG_IMAGES_ALLOWED_EXT = ['png', 'jpg', 'jpeg']  // Also we restrict only image mime type
export const BLOG_POST_SELECT = { 
  id: true, slug: true, title: true, content: true, read_time: true, headline: true, published_at: true,
  user_id: true, blog_id: true, 
  user: { select : { idNative:true, username: true, first_name: true, last_name: true, title: true, bio: true, image: true, location_name: true, created_at: true, website_url: true  } }, 
  blog_taggings: { 
    select: {
      blog_tag: { select: { id: true, name: true } }
    } 
  },
  blog_post_images: { 
    select: {
      image: { select: { id: true, name: true, width: true, height: true } }
    } 
  }, 
  cover_image: {
    select: { id: true, name: true, width: true, height: true }
  }

}
export const BLOG_POST_DEFAULT_BLOG = 1  // ID of the default blog 
export const BLOG_POST_DEFAULT_PUBLIC_BLOG = 2  // ID of the default public blog for any users


// USER PROFILE PAGE
export const USER_PROFILE_PAGE_PROPS_REVALIDATE = 1*60  // users/johnsmith 

//  export const APPCONNECTIONS_CONNECTIONS_BUILD_MAX = 250*6 // Max appConnections to load on Build 
//  export const APPCONNECTIONS_SOURCE_INTEGRATIONS_BUILD_MAX = 10000 // Max extApps of AppSource to load on Build // When App is Source
//  export const APPCONNECTIONS_EXT_ACTIONS_BUILD_MAX = 500 // Max extAppActions to load on Build 
//  export const APPCONNECTIONS_SIDE_TOP_SOURCES_MAX = 5 // Max sources to list in the side panel 
//  export const APPCONNECTIONS_RELATED_CATEGORIES_MAX = 10 // Max related categories to show in RC Section
//  export const APPCONNECTIONS_ALTERNATIVES_BUILD_MAX = 6 // Max appConnections to load on Build 

