
import { imageSizeByClass } from '@/lib/helpers'
import { websiteImageBaseUrl } from '@/lib/constants';

export default function AppLogo({ app={}, className, options={} }) {  

  const { loading='lazy' } = options // options currently used only for loading (lazy or eager)
  // get logo size in px by ClassName h- attribute (example: h-48)
  const size = imageSizeByClass(className)

  const hasImage = app?.image_updated_at || app?.image || app?.image?.formats

  const src = app ? `${websiteImageBaseUrl}/apps/logos/${app.slug}_${size}.png` : '';
  const alt = app ? app?.name : ''; 
  const title = app?.name || ''; 
  const color = app?.color ? `#${app?.color}` : '#D4D4D4';  // default is #818cf8 (bg-crisp-400) // another gray a bit darker '#D3D3D3'
   
  return (
    <>      
      {hasImage ? (
        <img 
          src={src} 
          alt={alt} 
          title={title} 
          className={className} 
          width={size} 
          height={size}
          loading={loading}
        />
      ) : (
        <div className={`${className} `}>
          <div 
            className="h-full w-full flex items-center justify-center rounded"
            style={color ? { backgroundColor: color } : {} }
          >
            {alt?.charAt(0)?.toUpperCase() }
          </div>
        </div>
      )}
    </>
  )
}


{/* <div className={`${className}`} >
  <div className={`inline-flex items-center bg-crisp-400 justify-center ${className}`} style={{ backgroundColor: color}} >
    <div className="text-3xl font-medium leading-none text-white ">
      { alt?.charAt(0)?.toUpperCase() }
    </div>
  </div>
</div> */}