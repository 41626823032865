
"use client";

import { useState, useRef, useEffect } from 'react'
import useSWR from 'swr'
import { useRouter } from 'next/navigation';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import AppLogo from '../../modules/apps/logo'
import { classNames } from '../../../lib/helpers'

import { SEARCH_MAX_ITEMS } from '../../../lib/build-constants'



export default function SearchBar(params) {
  const { 
    placeholder='Search',
    searchText='',
    setSearchText=()=>{},
    setSearchInputState=()=>{},  // sets searchInputState so parent component can use ths data
    searchInputState=true,  // also used to show/hide input and show/hide search icon(button) instead of input (on mobile)
    showSearchButton=false, // showing search button for going to search page
    showDropdownContainer=true, // in case we dont ned dropdown with results but will only use 

    classContainer='',
    classInput='',
    classDropdownContainer='',

  } = params
  


  const router = useRouter();

  // Search - NEW (static) - after 19.01.2021
  // const searchDataUrl = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/search/searchData`
  const searchDataUrl = `/api/search/searchData`
  const { data: searchData={}, error: searchDataError } = useSWR(searchText ? searchDataUrl : null, {}) 
  const { apps, categories } = searchData
  
  let appsVisible = apps?.filter(app => app?.name?.toLowerCase().startsWith(searchText?.toLowerCase()))?.slice(0, SEARCH_MAX_ITEMS)
  appsVisible = appsVisible?.map(a => {
    const { image, ...rest } = a
    return { ...rest, image_updated_at: image }  // neded for AppLogo to work properly
  })
  const categoriesVisible = categories?.filter(item => item?.name?.toLowerCase().startsWith(searchText?.toLowerCase()))?.slice(0, SEARCH_MAX_ITEMS)
  const dataVisible = [ {type: 'apps', items: appsVisible}, {type: 'categories', items: categoriesVisible} ] 

  // Generate path based on item type (apps, categories may have different path)
  const getSearchItemPath = (item, type) => {
    if (type === 'apps') { return `/${item?.slug}` }
    else if (type === 'categories') { return `/${item?.slug}` }  // `/apps/categories/${item?.slug}`
    else return '/'
  }

  // Search Input controls 
  const [searchInputActive, setSearchInputActive] = useState(false)  // Using to keep track if field is focused now or not. To show 'Start Typing' message. 

  // Search input and logo state management
  const useOutsideHandler = (ref1, ref2) => {
    useEffect(() => {
      // Handle Click outside both ref1 and ref2 elements
      const handleClickOutside = (event) => {
          if (ref1.current && !ref1.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
            // logger.info(`useOutsideHandler.handleClickOutside, Clicked outside both`);
            setSearchText('')
            setSearchInputState(false)
            setSearchInputActive(false)
          }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }  
  const searchResultsRef = useRef(null);  // combo box with search results
  const searchInputRef = useRef(null);  // search input ref
  useOutsideHandler(searchInputRef, searchResultsRef);

   
  return (
    <>

      <div className={classContainer}>
        
        {/* Search Inputs and Button  */}  
        <div className="min-w-0 flex-1">

          <label htmlFor="search" className="sr-only">
            Search
          </label>

          <div className="flex ">
            {/* <div className="relative flex items-stretch flex-grow focus-within:z-10"> */}
            <div className={classNames(searchInputState ? '' : 
              'hidden sm:block' , 
              'relative flex items-stretch flex-grow focus-within:z-10')}>
              
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>

              <input
                name="search"
                id="search"
                className={classInput}
                placeholder={placeholder}
                ref={searchInputRef}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onClick={ () => setSearchInputActive(true) }
              />
            </div>
            
            <button 
              className={classNames(searchInputState ? 
                'hidden' : 'sm:hidden', 
                'ml-auto flex-shrink-0 bg-white px-3 py-3 text-gray-400  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crisp-500')}
              onClick={() => { setSearchInputState(true) }}
            >
              <span className="sr-only">Search</span>
              <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {showSearchButton && (
              <button
                type="button"
                className={classNames(searchInputActive ? 'outline-none ring-1 ring-amber-500 border-amber-500' : '', 
                'relative inline-flex items-center  px-5 py-3 border border-amber-500 text-sm font-medium rounded-r-md text-white bg-amber-500 hover:bg-amber-600')}
              >
                <span className="sr-only">Search</span>
                <MagnifyingGlassIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            )}
          </div>

        </div>                
        
        {/* Search Dropdown */}        
        {showDropdownContainer && (
          <div 
            ref={searchResultsRef}
            className={classNames((appsVisible?.length>0 || categoriesVisible?.length>0) ? 
              'border-2' : '', 
              classDropdownContainer)}
          >

            
              {searchInputActive && (
                <ul className="w-full bg-gray-100">    
                  {searchText ? (                                    
                    !searchData && (
                      <li 
                        key='searching' 
                        className="px-3 py-3 text-gray-400"
                      >
                        Searching ...
                      </li>
                    )
                  ) : (
                    <li key='start-typing' className="px-3 py-3 text-gray-400">
                      Start typing
                    </li>
                  )}
                  </ul>
              )}

              {dataVisible?.map(dataBlock => (
                <ul key={dataBlock?.type} className="w-full">
                  {(dataBlock?.type != 'apps') && (dataBlock?.items?.length>0) && (
                    <li 
                      key={dataBlock?.type} 
                      className="px-3 py-0"
                    >
                      <span className='uppercase text-xs text-gray-400 font-semibold'>
                        {dataBlock?.type}
                      </span>
                    </li>                                    
                  )}
                  {dataBlock?.items?.map(item => (
                    <li key={item?.slug} className="px-3 py-3 hover:bg-crisp-100 sm:text-sm text-gray-700">

                      <div className="cursor-pointer" 
                          onClick={() => {                                             
                            setSearchInputActive(false);
                            router.push(getSearchItemPath(item, dataBlock?.type));                                             
                            setSearchText('');
                            setSearchInputState(false); 
                          }}
                      >
                        <div className="flex items-center">
                          {(dataBlock?.type === 'apps') && (
                            <AppLogo app={item} className="h-5 w-5 rounded-sm mr-2" />
                          )}
                          <span className='font-normal block truncate'>
                            {item?.name}
                          </span>
                        </div>
                      </div>
                      
                    </li>                                    
                  ))}
                </ul>
              ))}


          </div>
        )}

      </div>

    </>    
    
  )
}
